import React, { ReactNode } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { VIEW_MODE, BaseUpsertComponent } from '@wings/shared';
import {
  withRouter,
  DATE_FORMAT,
  Utilities,
  UIStore,
  IClasses,
  IOptionValue,
  ISelectOption,
  ViewPermission,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { Scrollable } from '@uvgo-shared/scrollable';
import { inject, observer } from 'mobx-react';
import { styles } from './UserProfile.style';
import {
  UserStore,
  UserResponseModel,
  UserSessionModel,
  SessionStore,
  IAPIUpdateOktaProfileRequest,
  CSDUserModel,
  CSDProfileModel,
  UserCacheModel,
} from '../../../Shared';
import { finalize, switchMap, takeUntil, debounceTime } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { NavigateFunction } from 'react-router';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { Dropdown, DROPDOWN_TRIGGER } from '@uvgo-shared/dropdown';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { fields } from './Fields';
import { action, observable } from 'mobx';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import { USER_ROLES, USER_STATUS } from '../../../Shared/Enums';
import Sessions from '../../../Sessions/Sessions';
import UserServiceNProduct from '../UserServiceNProduct/UserServiceNProduct';
import { CsdUserModel } from '@wings/notifications/src/Modules';
import { forkJoin, of } from 'rxjs';
import TemporaryPassword from '../TemporaryPassword/TemporaryPassword';
import GroupEditor from '../GroupEditor/GroupEditor';
import { ArrowBack } from '@material-ui/icons';
import { EnvironmentVarsStore, ENVIRONMENT_VARS } from '@wings-shared/env-store';
import {
  AutoCompleteControl,
  EDITOR_TYPES,
  DropdownItem,
  ViewInputControl,
  IGroupInputControls,
  IViewInputControl,
} from '@wings-shared/form-controls';
import { CustomLinkButton, DetailsEditorWrapper, EditSaveButtons, ConfirmDialog } from '@wings-shared/layout';
import { AuthStore } from '@wings-shared/security';

interface Props {
  classes?: IClasses;
  viewMode?: VIEW_MODE;
  params?: { mode: VIEW_MODE; id: string };
  navigate?: NavigateFunction;
  userStore?: UserStore;
  sessionStore?: SessionStore;
  updateEndDate: (selectedDate: string) => void;
}

@inject('userStore', 'sessionStore')
@observer
class UserProfile extends BaseUpsertComponent<Props, UserResponseModel> {
  @observable protected userData: UserResponseModel = new UserResponseModel({ id: '' });
  @observable userDetails: CSDUserModel = new CSDUserModel();
  @observable private csdUsers: CSDUserModel[] = [];
  @observable assumeUsers: UserCacheModel[] = [];
  @observable private selectedCSDUser: CSDUserModel = new CSDUserModel();
  @observable private selectedAssumeUser: UserCacheModel = new UserCacheModel();

  constructor(p: Props) {
    super(p, fields);
    const mode: string = this.props.params.mode?.toUpperCase();
    this.setViewMode(VIEW_MODE[mode] || VIEW_MODE.DETAILS);
  }

  public get hasError(): boolean {
    return this.form.hasError;
  }

  /* istanbul ignore next */
  componentDidMount() {
    UIStore.setPageLoader(true);
    const { userStore } = this.props;
    userStore
      .getUserData(this.userId)
      .pipe(
        switchMap(response => { 
          this.userData = new UserResponseModel(response);
          this.setFormValues(this.userData);
          return forkJoin([
            response.csdUserId != 0 ? userStore.loadCsdUsers(null, [ response.csdUserId ], true) : of(null),
            response.assumedIdentity ? userStore.getCsdUserProfile(response.assumedIdentity) : of(null),
          ]);
        }),
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ csdUsers, assumedUser ]: [CSDUserModel[] , CSDUserModel]) => {
        if ( csdUsers != null && csdUsers[0]?.id) {
          this.userDetails = csdUsers[0];
          this.selectedCSDUser = csdUsers[0];
        }
        if (assumedUser?.id) {
          const userCache = new UserCacheModel({
            csdUserId: assumedUser.id,
            firstName: assumedUser.firstName,
            lastName: assumedUser.lastName,
            username: assumedUser.email,
          });
          this.assumeUsers = [ ...this.assumeUsers, userCache ];
          this.selectedAssumeUser = userCache;
        }
        this.loadUserGroups(this.userData.id);
      });
  }

  /* istanbul ignore next */
  private loadUserGroups(id: string): void {
    UIStore.setPageLoader(true);
    this.props.userStore
      .loadUserGroups(id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private upsertUserData(): void { 
    const formValues: UserResponseModel = this.form.values();
    const user = new UserResponseModel({ ...this.userData, ...formValues });
    const assumeIdenitiy =
      this.selectedAssumeUser 
      && this.selectedAssumeUser.csdUserId != user.csdUserId
        ? this.selectedAssumeUser.csdUserId
        : null;
    const request: IAPIUpdateOktaProfileRequest = {
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      email: user.email,
      role: user.role.value.toString(),
      isInternal: user.isInternal,
      csdUserId: this.selectedCSDUser?.id || 0,
      status: user.status,
      endDate: user.endDate,
      assumedIdentity: assumeIdenitiy,
      
    };

    const { userStore } = this.props;
    UIStore.setPageLoader(true);
    userStore
      .upsertUserData(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.setViewMode(VIEW_MODE.DETAILS);
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: () => {
          this.componentDidMount();
        },
        
        error: error => {
          AlertStore.critical(error.message);
          if (this.userData.csdUserId) this.selectedCSDUser = this.userDetails;
        },
      });
  }

  /* istanbul ignore next */
  @action
  private loadCsdUsers(searchValue: string): void {
    if (searchValue.length <= 2) {
      return;
    }
    const { userStore } = this.props;
    this.loader.showLoader();
    userStore
      .loadCsdUsers(searchValue)
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        finalize(() => this.loader.hideLoader())
      )
      .subscribe(response => {
        this.csdUsers = response;
      });
  }

  @action
  private searchAssumeUsers(value: string): void {
    if (value.length <= 2) {
      return;
    }
    const { userStore } = this.props;
    userStore
      .searchUsersCache(value)
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        finalize(() => this.loader.hideLoader())
      )
      .subscribe(users => (this.assumeUsers = users));
  }

  private get userId(): string {
    const { id } = this.props.params;
    return id ? id : null;
  }

  private onAction(action: GRID_ACTIONS): void {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertUserData();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel();
        break;
    }
  }

  @action
  protected onCancel(): void {
    const viewMode = this.props.params.mode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.viewMode = VIEW_MODE.DETAILS;
      this.form.reset();
      this.setFormValues(this.userData);
      return;
    }
    this.navigateToUserManagement();
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
  }

  /* istanbul ignore next */
  private navigateToUserManagement(): void {
    this.props.navigate('/user-management/okta-users');
  }

  /* istanbul ignore next */
  private revokeToken(id: string): void {
    UIStore.setPageLoader(true);
    this.props.userStore
      .revokeToken(id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          ModalStore.close();
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(
        (result: string) => {
          AlertStore.info(result);
        },
        (error: AxiosError) => AlertStore.critical(error.message)
      );
  }

  private openUserSessionModal(user: UserResponseModel) {
    ModalStore.open(
      <Sessions
        user={user}
        sessionStore={this.props.sessionStore}
        openSessionDeleteConfirmation={(session: UserSessionModel, user: UserResponseModel) =>
          this.deleteConfirmationForUserSession(session, user)
        }
      />
    );
  }

  private deleteConfirmationForUserSession(session: UserSessionModel, user: UserResponseModel): void {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to delete this session?"
        yesButton="Yes"
        onNoClick={() => this.openUserSessionModal(user)}
        onCloseClick={() => this.openUserSessionModal(user)}
        onYesClick={() => this.deleteSession(session, user)}
      />
    );
  }

  /* istanbul ignore next */
  private deleteSession(session: UserSessionModel, user: UserResponseModel): void {
    UIStore.setPageLoader(true);
    this.props.sessionStore
      .deleteSession(user.oktaUserId, session)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          ModalStore.close();
          this.openUserSessionModal(user);
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(
        () => AlertStore.info('Session deleted successfully'),
        (error: AxiosError) => AlertStore.info(error.message)
      );
  }

  /* istanbul ignore next */
  private getToggleActivationDialog(title: string, message: string, yesButton: string) {
    ModalStore.open(
      <ConfirmDialog
        title={title}
        message={message}
        yesButton={yesButton}
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.toggleActivation()}
      />
    );
  }

  /* istanbul ignore next */
  @action
  private toggleActivation() {
    UIStore.setPageLoader(true);
    ModalStore.close();
    const { id, status } = this.userData;
    this.props.userStore
      .toggleActivation(id, status)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          if (response.Data) {
            const updatedStatus = status === USER_STATUS.ACTIVE ? USER_STATUS.DEPROVISIONED : USER_STATUS.PROVISIONED;
            this.userData = new UserResponseModel({ ...this.userData, status: updatedStatus });
            this.setFormValues(this.userData);
          }
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  @action
  private expirePassword(user: UserResponseModel): void {
    UIStore.setPageLoader(true);
    this.props.userStore
      .expirePassword(user.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((temporaryPassword: string) => {
        ModalStore.close(); // closing the current dialog
        if (temporaryPassword) {
          ModalStore.open(<TemporaryPassword title="Temporary Password" temporaryPassword={temporaryPassword} />);
        }
      });
  }

  /* istanbul ignore next */
  @action
  private deleteOktaAndUpdateCSDProfile(user: UserResponseModel) {
    ModalStore.close();
    UIStore.setPageLoader(true);
    this.props.userStore.deleteUser(user.userId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          if(user.csdUserId){
            this.props.userStore.updateCsdUserProfile(CSDProfileModel.obfuscate(user.csdUserId));
          }
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(
        () => {
          AlertStore.info('User deleted successfully!');
          this.navigateToUserManagement();
        },
        (error: AxiosError) => AlertStore.critical(error.message)
      );
  }

  /* istanbul ignore next */
  @action
  private unlockUser(user: UserResponseModel): void {
    UIStore.setPageLoader(true);
    this.props.userStore
      .unlockUser(user.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          ModalStore.close();
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((isUnlocked: boolean) => {
        if (isUnlocked) {
          AlertStore.info('User is unlocked successfully!!');
        }
      });
  }

  /* istanbul ignore next */
  protected get groupInputControls(): IGroupInputControls[] {
    const isDisabled = this.props.viewMode !== VIEW_MODE.NEW;
    const env = new EnvironmentVarsStore();
    const isADProvider: boolean = this.userData?.provider === env.getVar(ENVIRONMENT_VARS.UWA_AD_PROVIDER);
    return [
      {
        title: 'UserProfile',
        inputControls: [
          {
            fieldKey: 'firstName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isADProvider,
          },
          {
            fieldKey: 'lastName',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isADProvider,
          },
          {
            fieldKey: 'email',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isDisabled,
          },
          {
            fieldKey: 'username',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isDisabled,
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isDisabled,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.API_DATE_FORMAT,
            allowKeyboardInput: false,
            minDate: moment().format(DATE_FORMAT.API_DATE_FORMAT),
          },
          {
            fieldKey: 'isInternal',
            type: EDITOR_TYPES.CHECKBOX,
            isDisabled: isDisabled,
          },
          {
            fieldKey: 'csdUserId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isDisabled,
          },
          {
            fieldKey: 'userGuid',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: true,
          },
          {
            fieldKey: 'role',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.getUserRoles(),
          },
          {
            fieldKey: 'assumedIdentity',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: isDisabled,
          },
        ],
      },
    ];
  }

  private getUserRoles(): ISelectOption[] {
    return Object.keys(USER_ROLES).map(key => ({ label: key, value: USER_ROLES[key] }));
  }

  private get dropdownOptionsList() {
    const env = new EnvironmentVarsStore();
    const isADProvider: boolean = this.userData?.provider === env.getVar(ENVIRONMENT_VARS.UWA_AD_PROVIDER);
    const isInternal: boolean = !this.userData?.isInternal;
    const isOktaProvider: boolean = this.userData?.provider === 'OKTA';
    return [
      {
        title: 'Unlock',
        onClick: () => {
          ModalStore.open(
            <ConfirmDialog
              title="Confirm Unlock"
              message="Are you sure you want to unlock this User?"
              yesButton="Unlock"
              onNoClick={() => ModalStore.close()}
              onYesClick={() => this.unlockUser(this.userData)}
            />
          );
        },
        isDisabled: this.userData?.status !== USER_STATUS.LOCKED_OUT,
      },
      {
        title: 'Revoke Token',
        onClick: () =>
          ModalStore.open(
            <ConfirmDialog
              title="Revoke Token"
              message="Revoke token for user, this will invalidate their current login session."
              yesButton="Continue"
              onNoClick={() => ModalStore.close()}
              onYesClick={() => this.revokeToken(this.userId)}
            />
          ),
      },
      {
        title: 'Session',
        onClick: () => this.openUserSessionModal(this.userData),
      },
      {
        title: 'Activate',
        onClick: () => {
          const message = 'Are you sure you want to activate this User?';
          this.getToggleActivationDialog('Confirm Activation', message, 'Activate');
        },
        isDisabled: this.userData.status !== USER_STATUS.DEPROVISIONED || isADProvider,
      },
      {
        title: 'Deactivate',
        onClick: () => {
          const message = isADProvider 
            ? 'Deactivating a user sourced by Active Directory will result in them being disconnected.'+
        ' Are you sure you want to deactivate this User?' 
            :'Are you sure you want to deactivate this User?';
          this.getToggleActivationDialog('Confirm Deactivation', message, 'Deactivate');
        },
        isDisabled:
          this.userData?.status == USER_STATUS.DEPROVISIONED
      },
      {
        title: 'Temporary Password',
        onClick: () => {
          this.expirePassword(this.userData);
        },
        isDisabled: this.userData.status === USER_STATUS.DEPROVISIONED || !isOktaProvider || isADProvider,
      },
      {
        title: 'Delete',
        onClick: () => {
          ModalStore.open(
            <ConfirmDialog
              title={`Remove ${this.userData.username} user?`}
              message="Do you really want to remove this user? This process cannot be undone."
              yesButton="Continue"
              onNoClick={() => ModalStore.close()}
              onYesClick={() => this.deleteOktaAndUpdateCSDProfile(this.userData)}
            />
          );
        },
        isDisabled:
          this.userData?.status !== USER_STATUS.DEPROVISIONED
      },
    ];
  }

  @action
  private setSearchValue(selectedCSDUser: CSDUserModel): void {
    if (!selectedCSDUser) {
      this.csdUsers = [];
      this.selectedCSDUser = null;
      return;
    }
    this.selectedCSDUser = selectedCSDUser;
  }

  @action
  private setSearchAssumeValue(selectedUser: UserCacheModel): void {
    if (!selectedUser) {
      this.assumeUsers = [];
      this.selectedAssumeUser = null;
      return;
    }
    this.selectedAssumeUser = selectedUser;
  }

  private get dropdownOptions(): ReactNode {
    return (
      <React.Fragment>
        {this.dropdownOptionsList
          .map(({ title, onClick, isDisabled }) => (
            <DropdownItem key={title} isDisabled={isDisabled} onClick={onClick}>
              {title}
            </DropdownItem>
          ))}
      </React.Fragment>
    );
  }

  private get hasWritePermission(): boolean {
    return AuthStore.permissions.hasPermission('write_users');
  }

  private get headerActions(): ReactNode {
    const { classes } = this.props;
    return (
      <>
        <div></div>
        <div className={classes.flexWrap}>
          <ViewPermission hasPermission={!this.isEditable}>
            <Dropdown popperContent={this.dropdownOptions} trigger={DROPDOWN_TRIGGER.CLICK} autoclose={false}>
              <PrimaryButton disabled={!this.hasWritePermission} variant="contained">
                More
                <ArrowDropDownOutlinedIcon className={classes.dropdown} />
              </PrimaryButton>
            </Dropdown>
          </ViewPermission>
          <EditSaveButtons
            disabled={this.hasError || UIStore.pageLoading}
            hasEditPermission={this.hasWritePermission}
            isEditMode={this.isEditable}
            onAction={action => this.onAction(action)}
          />
        </div>
      </>
    );
  }

  /* istanbul ignore next */
  private get csdUserInputControls(): IGroupInputControls {
    return {
      title: '',
      inputControls: [
        {
          fieldKey: 'fullName',
          label: 'Full Name',
        },
        {
          fieldKey: 'name',
          label: 'User Name',
        },
        {
          fieldKey: 'email',
          label: 'Email',
        },
        {
          fieldKey: 'customerNumber',
          label: 'Customer Number',
        },
      ],
    };
  }

  /* istanbul ignore next */
  private get csdUserDetails(): ReactNode {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.boxSection}>
          {this.loader.spinner}
          <Typography variant="h6" className={classes.title}>
            CSD PROFILE
          </Typography>
          <div className={classes.flexWrap}>
            {this.csdUserInputControls.inputControls.map((field, index) => (
              <ViewInputControl
                key={field.fieldKey}
                type={EDITOR_TYPES.TEXT_FIELD}
                classes={{
                  flexRow: classNames({
                    [classes.inputControl]: true,
                  }),
                }}
                field={{ value: this.userDetails[field.fieldKey], label: field.label }}
                isEditable={false}
              />
            ))}
          </div>
        </div>
        <div className={classes.boxGroup}>
          {this.userDetails.servicesNProducts.length > 0 && (
            <UserServiceNProduct servicesNProducts={this.userDetails.servicesNProducts} />
          )}
        </div>
      </>
    );
  }

  public render(): ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes.mainWrapper}>
        <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
          <div className={classes.btnSection}>
            <CustomLinkButton
              to="/user-management/okta-users"
              title='Back To Users'
              startIcon={<ArrowBack />}
            />
          </div>
          <div className={classes.mainSection}>
            <div className={classes.boxSection}>
              <Typography variant="h6" className={classes.title}>
                OKTA PROFILE
              </Typography>
              {this.groupInputControls.map(groupInputControl => {
                return (
                  <div key={this.userId} className={classes.flexWrap}>
                    {groupInputControl.inputControls
                      .filter(inputControl => !inputControl.isHidden)
                      .map((inputControl: IViewInputControl, index: number) => {
                        if (Utilities.isEqual(inputControl.fieldKey, 'csdUserId') && this.isEditable) {
                          return (
                            <>
                              <div className={classes.searchContainer}>
                                <Typography className={classes.titleHeading}>Mapped CSD User</Typography>
                                <AutoCompleteControl
                                  placeHolder="Search CSD Users"
                                  options={this.csdUsers.filter(
                                    x => Boolean(x.name) && Boolean(x.email) && Boolean(x.fullName)
                                  )}
                                  value={this.selectedCSDUser}
                                  filterOption={options =>
                                    options.map(option => {
                                      return {
                                        ...option,
                                        label: (option as CsdUserModel).email,
                                      };
                                    })
                                  }
                                  onDropDownChange={selectedOption =>
                                    this.setSearchValue(selectedOption as CSDUserModel)
                                  }
                                  onSearch={(searchValue: string) => this.loadCsdUsers(searchValue)}
                                />
                              </div>
                            </>
                          );
                        }
                        if (Utilities.isEqual(inputControl.fieldKey, 'assumedIdentity') && this.isEditable) {
                          return (
                            <>
                              <div key={this.userId} className={classes.searchContainer}>
                                <Typography className={classes.titleHeading}>Assume Identity</Typography>
                                <AutoCompleteControl
                                  placeHolder="Search Users"
                                  options={this.assumeUsers}
                                  value={this.selectedAssumeUser}
                                  onDropDownChange={selectedOption =>
                                    this.setSearchAssumeValue(selectedOption as UserCacheModel)
                                  }
                                  onSearch={(searchValue: string) => this.searchAssumeUsers(searchValue)}
                                />
                              </div>
                            </>
                          );
                        }
                        return (
                          <ViewInputControl
                            {...inputControl}
                            key={inputControl.fieldKey}
                            customErrorMessage={inputControl.customErrorMessage}
                            field={this.getField(inputControl.fieldKey)}
                            isEditable={this.isEditable}
                            isExists={inputControl.isExists}
                            classes={{
                              flexRow: classNames({
                                [classes.inputControl]: true,
                              }),
                            }}
                            onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey)}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </div>
            <div className={classes.boxSection}>
              <GroupEditor key={this.selectedCSDUser?.id} selectedUser={this.userData} />
            </div>
            {this.userData.csdUserId > 0 && <div>{this.csdUserDetails}</div>}
          </div>
        </DetailsEditorWrapper>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(UserProfile));
export { UserProfile as PureUserProfile };
export const UserProfileWithoutRoute = withStyles(styles)(UserProfile);
