import { IAPILogResponse } from '../Interfaces';
import { UserModel } from '@wings/notifications/src/Modules';
import { ISelectOption, modelProtection } from '@wings-shared/core';
import { LogContextModel } from './LogContext.model';

@modelProtection
export class LogModel {
  id: string = '';
  oktaUserId: string = '';
  username: UserModel = null;
  message: string = '';
  event: ISelectOption;
  status: ISelectOption;
  source: ISelectOption;
  target: ISelectOption;
  timeStamp: string = '';
  context: LogContextModel = new LogContextModel();

  constructor(data?: Partial<LogModel>) {
    Object.assign(this, data);
  }

  static deserialize(log: IAPILogResponse): LogModel {
    if (!log) {
      return new LogModel();
    }

    const data: Partial<LogModel> = {
      id: log.Id,
      oktaUserId: log.OktaUserId,
      username: new UserModel({
        id: log.Id,
        name: log.Username,
      }),
      message: log.Message,
      event: { label: log.Event, value: log.Event },
      status: { label: log.Status, value: log.Status },
      source: { label: log.Source, value: log.Source },
      target: { label: log.Target, value: log.Target },
      timeStamp: log.Timestamp || '',
      context: LogContextModel.deserialize(log.Context),
    };
    return new LogModel(data);
  }

  static deserializeList(log: IAPILogResponse[]): LogModel[] {
    return log
      ? log
        .map((logs: IAPILogResponse) =>
          LogModel.deserialize(logs))
      : [];
  }
}