import React, { ReactNode } from 'react';
import UserManagement from './indexV2';
import { HubConnectionStore, NOTIFICATIONS_EVENTS, AuthStore } from '@wings-shared/security';
import { AlertStore } from '@uvgo-shared/alert';

export default class App extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      this.subscribeToNotifications();
      this.subscribeToUserUpdated();
    }, 2000);
  }
  
  componentWillUnmount() {
    HubConnectionStore.connection?.off(NOTIFICATIONS_EVENTS.JOB_STATUS_NOTIFICATION);
    HubConnectionStore.connection?.off(NOTIFICATIONS_EVENTS.USER_UPDATED_LISTENER);
  }

  /* istanbul ignore next */
  private subscribeToNotifications(): void {
    HubConnectionStore.connection?.on(NOTIFICATIONS_EVENTS.JOB_STATUS_NOTIFICATION, notification => {
      if(notification?.data){
        if (notification?.data?.isSuccess) {
          AlertStore.info(notification?.data?.message);
        }else{
          AlertStore.critical(notification?.data?.message);
        }
      }
    });
  }

  /* istanbul ignore next */
  private subscribeToUserUpdated(): void {
    HubConnectionStore.connection?.on(NOTIFICATIONS_EVENTS.USER_UPDATED_LISTENER, notification => {
      if(notification?.data && AuthStore.user?.userGUID != notification?.data?.triggeredByUserGuid){
        AlertStore.info(`User ${notification?.data?.user.username} updated successfully!`);
      }
    });
  }

  public render(): ReactNode {
    return <UserManagement basePath="user-management" />;
  }
}
