import React, { FC, useState } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useStyles } from './UserProfileRolesGrid.style';
import { UserProfileRolesModel } from '../../../Shared';
import { AuthStore, USER_GROUP } from '@wings-shared/security';
import { IClasses, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { ChildGridWrapper, ConfirmDialog } from '@wings-shared/layout';
import { ExpandCollapseButton } from '@wings-shared/form-controls';
import {
  CustomAgGridReact,
  AgGridActions,
  AgGridActionButton,
  AgGridChipViewStatus,
  AgGridFilterHeader,
  useGridState,
  useAgGrid,
} from '@wings-shared/custom-ag-grid';
import { observable } from 'mobx';
import { LOGS_FILTERS } from '../../../Shared/Enums';

interface Props {
  classes?: IClasses;
  rolesField: UserProfileRolesModel[];
  openRoleFieldDialog: (rolesField: UserProfileRolesModel, viewMode: VIEW_MODE) => void;
  upsertRoleField: (rolesField: UserProfileRolesModel) => void;
  deleteRoleField: (id: number) => void;
  userId: string;
}

const UserProfileRolesGridV2: FC<Props> = ({ ...props }) => {
  const [ showAssociation, setShowAssociation ] = useState(true);
  const classes = useStyles();
  const gridState = useGridState();
  const agGrid = useAgGrid<LOGS_FILTERS, UserProfileRolesModel>([], gridState);

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
  }

  const confirmRemoveRoleField = (rowIndex: number): void => {
    const data: UserProfileRolesModel = agGrid._getTableItem(rowIndex);
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this field?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => props.deleteRoleField(data.id)}
      />
    );
  }

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Role',
      field: 'name',
    },
    {
      headerName: 'Description',
      field: 'description',
    },
    {
      headerName: 'Association',
      hide: !showAssociation,
      field: 'attributes',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        const customer = value?.find(x => x.type === 'Customer')?.value;
        const site = value?.find(x => x.type === 'Site')?.value;
        return `${customer || ''} / ${site || ''}`;
      },
    },
    {
      headerName: 'Permissions',
      field: 'permissions',
      cellRenderer: 'agGridChipViewStatus',
      cellRendererParams: {
        isPlainText: true,
      },
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionButtonRenderer',
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        onAction: node => {},
        isEditOrDelete: true,
        isDisabled: () => false,
        isHidden: node => !AuthStore.userHasAccess(USER_GROUP.USER_MANAGEMENT_ADMIN),
        onClick: (node, isEditable) => {
          if (isEditable) return props.openRoleFieldDialog(node.data, VIEW_MODE.EDIT);
          return confirmRemoveRoleField(node.rowIndex);
        },
      },
    },
  ];

  const gridActionProps = (): object => {
    return {
      showDeleteButton: true,
      getDisabledState: () => gridState.hasError,
      getEditableState: () => false,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => gridActions(action, rowIndex),
    };
  }

  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: this,
      columnDefs,
      isEditable: false,
      gridActionProps,
    });
    return {
      ...baseOptions,
      suppressClickEdit: true,
      groupHeaderHeight: 0,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
        minWidth: 180,
        menuTabs: [ 'filterMenuTab' ],
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        agGridChipViewStatus: AgGridChipViewStatus,
        actionButtonRenderer: AgGridActionButton,
        agColumnHeader: AgGridFilterHeader,
      },
      pagination: false,
    };
  }

  const showAssociations = (value: boolean) => {
    setShowAssociation(value);
    agGrid.setColumnVisible('attributes', value);
  }

  return (
    <>
      <div className={classes.checkBoxSection}>
        <div className={classes.checkBox}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => showAssociations(e.target.checked)} checked={showAssociation} />
            }
            label="Show Association"
          />
        </div>
        <div className={classes.resizeIcon}>
          <ExpandCollapseButton onExpandCollapse={() => agGrid.autoSizeColumns()} />
        </div>
      </div>
      <div className={classes.container}>
        <ChildGridWrapper
          onAdd={() => props.openRoleFieldDialog(new UserProfileRolesModel(), VIEW_MODE.NEW)}
          hasAddPermission={true}
          disabled={gridState.isProcessing}
          title="Add Role"
        >
          <CustomAgGridReact
            isRowEditing={gridState.isRowEditing}
            rowData={props.rolesField}
            gridOptions={gridOptions()}
          />
        </ChildGridWrapper>
      </div>
    </>
  );
}

export default observer(UserProfileRolesGridV2);
